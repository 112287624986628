import React from "react";

const FeatureOne = ({ services }) => {

  return (
    <div className="section_padding">
      <div className="hero-section-title text-center">
              <h1>Nuestros servicios</h1>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-wrap text-center justify-content-center">
          {services.items.map(({ iconUrl, title, description }, index) => (
            <div className="feature_service_box_width" key={`feature-one-${index}`}>
              <img src={iconUrl} alt=''/>
              <p>{title}</p>
            </div>
          ))}
        </div>
      </div>
          
    </div>

  );
};

export default FeatureOne;
