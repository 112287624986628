import React, { useEffect, useState,} from "react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { graphql, useStaticQuery } from "gatsby"
import healthIcon from '../images/healthcare-icon-solid-white.png'
import pharmaMarker from '../images/pharma-marker.png'

const isBrowser = typeof window !== "undefined"

const containerStyle = {
  width: '100%',
  minHeight: '100%'
};


const ContactOne = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
          pharmacyMarkers {
          id  
          name
          lat
          long
          province
          city
          streetName
          streetLvl
          }
          hours{
            day
            hour
            pharmacy
          }
       colors {
          primaryColor
          secondaryColor
        }
      }
    }
  `)
  const { pharmacyMarkers, hours } = data.pharmacy
  // const { primaryColor} = data.pharmacy.colors
  const [activeMarker, setActiveMarker] = useState(null)
  const [branchesListPaddingBottom, setbranchesListPaddingBottom] = useState(0)

  const onMarkerClick = (idx) => {
    setActiveMarker(idx)
  }

  useEffect(() => {
    if (isBrowser) {
          const scrollableBranches = document.getElementById('scrollableBranches');
    scrollableBranches.scrollTo({ top: (document.getElementById(`branch${activeMarker}`)?.offsetTop??0) - (document.getElementById(`branch${activeMarker}`)?.parentElement?.offsetTop??0), behavior: 'smooth' }); 
    }
  
  }, [activeMarker])

  useEffect(() => {
    if (isBrowser && pharmacyMarkers?.length > 1) {
          setbranchesListPaddingBottom(document.getElementById('branchesList').scrollHeight + document.getElementById('branchesContainer').clientHeight)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  

  // const testMarkers = [
  //   {
  //     lat: -34.58199390000001,
  //     long: -58.40286469999999,
  //     name: "Sede Central",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 2102,
  //     streetName: "AV DEL LIBERTADOR",
  //   },
  //   {
  //     lat: -34.583,
  //     long: -58.405,
  //     name: "Sede 1",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 2300,
  //     streetName: "Calle 1",
  //   },
  //   {
  //     lat: -34.580,
  //     long: -58.405,
  //     name: "Sede 2",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 4300,
  //     streetName: "Calle 2",
  //   },
  //   {
  //     lat: -34.580,
  //     long: -58.408,
  //     name: "Sede 3",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 1234,
  //     streetName: "Calle 3",
  //   },
  //   {
  //     lat: -34.577,
  //     long: -58.408,
  //     name: "Sede 4",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 3244,
  //     streetName: "Calle 4",
  //   },
  //   {
  //     lat: -34.576,
  //     long: -58.411,
  //     name: "Sede 5",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 3142,
  //     streetName: "Calle 5",
  //   },
  //   {
  //     lat: -34.583,
  //     long: -58.402,
  //     name: "Sede 6",
  //     province: "Ciudad Autónoma de Buenos Aires",
  //     streetLvl: 543,
  //     streetName: "Calle 6",
  //   },

  // ]
  // const pharmacyMarkers = testMarkers
  const centers = pharmacyMarkers.map((marker) => {
    return {
      lat: marker.lat,
      lng: marker.long
    }
  })

  const mapOptions = {
    
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      {
        "stylers": [{
          "saturation": -100
        }]
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.government",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels",

        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.local",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.station.bus",
        stylers: [{ visibility: "off" }],
      },
    ]
  }
   
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCaZQmkoYdYLQzcLAUcIg2ak002aowQaEg"
  })

  const onLoad = React.useCallback(function callback(map) {

    const bounds = isBrowser ? new window.google.maps.LatLngBounds(centers[0]) : null
    centers.map(center => (
      bounds.extend(center)
    ))
    map.fitBounds(bounds)
    var listener = window.google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      window.google.maps.event.removeListener(listener);
    });
  }, [centers])
  
  return (
      <section className="contact_form_area">
      <div className="contact_form_width">
        <div className="map_height">
          {isLoaded && <GoogleMap

              mapContainerStyle={containerStyle}
              onClick={() => setActiveMarker(null)}
              options={mapOptions}
              onLoad={onLoad}
            >
              {pharmacyMarkers?.map((marker, idx) => {
                const isActive = activeMarker === idx

                return <Marker
                  key={`marker-${marker.title}${idx}`}
                  position={{
                    lat: marker.lat,
                    lng: marker.long
                  }}
                  onClick={() => onMarkerClick(idx)}
                  icon={
                    {
                      url: healthIcon,
                      scaledSize: { width: isActive ? 34 : 24, height: isActive ? 34 : 24 }
                    }
                  }
                                  />
              }
              )}
             
              { /* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>}

        </div>
 
      </div>
      <div className="d-flex flex-column flex-fill">
        <div className='d-flex py-3 justify-content-center primary-background'>
          <h4 className="mx-auto my-auto"
            style={{
            color: 'white'
          }}
          >
            <img className="mx-2 mb-1" src={pharmaMarker} alt='' height={'25px'} />
            Nuestras sucursales</h4>
        </div>
        <div className="contact_form_width contact-right flex-wrap" id='branchesContainer'>

          <div className="contact-right-branches justifiy-content-center-mobile" id='scrollableBranches'>
            <div id='branchesList' className="contact-rigth-branches-list"
              style={{
                paddingBottom: branchesListPaddingBottom
              }}
            >
              {pharmacyMarkers?.map((marker, idx) =>
                  <div
                  id={`branch${idx}`}
                  key={`marker-${marker.name}`}
                  className={`py-3 border-bottom branch text-center-mobile ${activeMarker === null ? '' : activeMarker === idx ? 'active-marker-tag' : 'inactive-marker-tag'}`}
                  onClick={() => onMarkerClick(idx)}
                  onKeyDown={() => null}
                  role='button'
                  tabIndex={0}
                >

                  <h5 className="marker-header">{marker.name}</h5>
                  <h6 className="marker-body">{`${marker.streetName} ${marker.streetLvl}`}</h6>
                  <h6 className="marker-body">{`${marker.city}`}</h6>
                  <h6 className="marker-body">{`${marker.province}`}</h6>
                    {
                      hours.filter(hour => hour.pharmacy === marker.id).map((hourLine,idx) => <h6 key={`hourLine${marker.id+idx}`} className="marker-body">{`${hourLine.day??''} ${hourLine.hour??''}`}</h6>)
                    }
                </div>
              )}
            </div>

          </div>



        </div>
      </div>
        
      </section>
    
  );
};

export default ContactOne;
