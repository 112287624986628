import React from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import FeatureOne from "@/components/feature-one";
import BlogOne from "@/components/blog-one";
import ContactOne from "@/components/contact-one";
import { graphql, useStaticQuery } from "gatsby"
import CustomCarousel from "../components/slider";
import CtaOne from "@/components/cta-one";
import GalleryOne from "../components/gallery-one";


const HomeOne = () => {

  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        fiscal_data
        folLink
        colors {
          secondaryColor
          primaryColor
        }
        slogan
        name
        logo
        contactMeans {
          phones
          mobile_phone
          email        }
        carouselItems {
          imgUrl
          imgMobileUrl
          pageName {
            text
            color
          }
          pageDescription {
            text
            color
          }
          linksTo
          useCTA
        }
        hours {
          day
          hour
        }
        promotions {
          img
          title
          description
          linksTo
          useCTA
        }
        services {
          intro {
            title
            text
          }
          items {
            iconUrl
            title
            description
          }
        }
        pharmacyMarkers {
          name
          lat
          long
          province
          city
          streetName
          streetLvl
        }
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)
  const { slogan, carouselItems, services} = data.pharmacy;

  return (
    <MenuContextProvider>
      <Layout PageTitle="Inicio">
        <HeaderOne />
        <CustomCarousel items={carouselItems} useCTAButton={false} />
        <FeatureOne services={services} />
        <GalleryOne />
        <CtaOne slogan={slogan}/>
        <BlogOne />
        <ContactOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default HomeOne;
