import React from "react";
import GalleryCard from "@/components/gallery-card";
import { graphql, useStaticQuery } from "gatsby"


const GalleryOne = () => {
    const data = useStaticQuery(graphql`
    query {
      pharmacy {
        promotions {
          img
          title
          description
          linksTo
          useCTA
        }
      }
    }
  `)
  const { promotions } = data.pharmacy
  
  if (!promotions || !promotions.length) return null

  return (
    <div className="section_padding">
      <div className="hero-section-title text-center mx-3">
        <h1>Categorías destacadas</h1>
      </div>
      <div className="d-flex justify-content-center" style={{ marginBottom: '60px' }}>
      <section className="gallery_area">
          {promotions.map(({ title, img, linksTo, useCTA }, index) => (
        <GalleryCard
          key={`gallery-one-${index}`}
          src={img}
          alt=""
          title={title}
          url={useCTA? linksTo : ''}
        />
      ))}
    </section>
    </div>
    </div>

   
  );
};

export default GalleryOne;
