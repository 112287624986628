import React from "react"
import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel"
import informCustomEvent from "../utils/analytics";

const isBrowser = typeof window !== "undefined"

export default function CustomCarousel({ items }) {

  const [width, setWidth] = useState(isBrowser ? window.innerWidth : null); 
  const handleWindowSizeChange =()=> {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (isBrowser) {
        window.addEventListener('resize', handleWindowSizeChange);
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
  }, []);
  const isMobile = width <= 461;
  const vw = 1200;
  const headHeight = isBrowser? document.getElementById('header')?.clientHeight :0
  const [headerHeight, setHeaderHeight] = useState('')
  // const [isBannerImgLoaded, setIsBannerImgLoaded] = useState(false)
  // const [isMobileBannerImgLoaded, setIsMobileBannerImgLoaded] = useState(false)

  const renderImage = (item, vw) => {
    if (item.useCTA) {
      // Only use Link for internal links (https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links)
      return (
        <a href={item.linksTo} target="_blank" rel="noopener noreferrer"
           onClick={() => informCustomEvent("BannerClick", { banner: item.name })}
        >
          <img
            // onLoad={setIsMobileBannerImgLoaded(true)}
            src={item.imgMobileUrl}
            className='carousel-slider showOnMobile'
            style={{
              width: "100%",
              objectPosition: "center", 
            }}
            alt=""
          ></img>
          <img
            // onLoad={setIsBannerImgLoaded(true)}
            src={item.imgUrl}
            className='carousel-slider showOnDesktop'
            style={{
              width: "100%",
              objectPosition: "center",
            }}
            alt=""
          ></img>
        </a>
      )
    } else {
      return (
        <>
          <img
            // onLoad={setIsMobileBannerImgLoaded(true)}
            src={item.imgMobileUrl}
            className='carousel-slider showOnMobile'
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt=""
          ></img>
          <img
            // onLoad={setIsBannerImgLoaded(true)}
            src={item.imgUrl}
            className='carousel-slider showOnDesktop'
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt=""
          ></img>
        </>

        
      )
    }
  }

  function Item({ item }) {
    const vw = 1200;
    // let pageNameColor = item.pageName.color
    let pageDescColor = item.pageDescription.color
    return (
      <>
        {renderImage(item, vw)}
        <div
          style={{
            position: "absolute",
            bottom: "20%",
            left: "10%",
            textShadow: "1px 1px 0.4rem black",
          }}
        >
          <h1 style={{ color: "var(--primaryColor)" }}>{item.pageName.text}</h1>
          <p style={{ color: pageDescColor, fontSize: "smaller" }}>{item.pageDescription.text}</p>
        </div>
      </>
    )
  }

  useEffect(() => {
    setHeaderHeight(isBrowser ? document.getElementById('header')?.clientHeight : '')
  }, [headHeight])

  if (!items || !items.length ) return null
    
  return (
    <>
      <div
        className="showOnBigDesktop"
        style={{ height: headerHeight }}>
      </div>

      {/* <div
        className={`banner_loader ${isBannerImgLoaded && isMobileBannerImgLoaded ? 'd-none' : 'd-flex'}`} >
        <div
          className="showOnMobile"
          style={{ height: headerHeight }}>
        </div>
        <div className={`spinner-border d-flex m-auto`}
          role='status'
          style={{ padding: '25px' }}
        >
        </div>
      </div> */}
        <Carousel
      className="header_slider_area"
    animation="fade"
    indicators={false}
    navButtonsAlwaysVisible={items?.length > 1 && !isMobile }
    navButtonsAlwaysInvisible={items?.length === 1 || isMobile}
    indicatorContainerProps={{
      style: {
          marginTop: '-2rem',
          paddingBottom: "0.5rem"
      }
    }}
    navButtonsWrapperProps={{
      style: {
        margin: vw < 800 ? '0px 10px' : '0 100px'
      }
    }}
    navButtonsProps={{
      style: {
        padding: vw < 800 ? '6px' : ''
      }
    }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
    </>

  )
}



